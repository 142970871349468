@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Volkhov&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

* {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  scroll-behavior: smooth;
}
.vol{
  font-family: 'Volkhov', serif;
}
.roboto {
  font-family: "Roboto Flex", sans-serif;
  font-optical-sizing: auto; 
  font-style: normal;
}
/* .pop{
  font-family: 'Poppins', sans-serif;
} */
/* .nunito{
  font-family: 'Nunito Sans', sans-serif;
} */
/* .roboto{
  font-family: 'Roboto', sans-serif;
} */
.dot{
  z-index:100;
}
.flip{
  transform: rotate(90deg);
transform: scaleX(-1);
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

/* Add this CSS to your stylesheet */
.scroll-container {
  width: 100%; /* Ensure the container takes up the full width */
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; /* Hide vertical scrolling */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

.scroll-container::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Webkit (Chrome, Safari, etc.) */
}

.fan{
  position: fixed;
  z-index: 100;
}

@font-face {
    font-family: 'Material Symbols Outlined';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialsymbolsoutlined/v107/kJF1BvYX7BgnkSrUwT8OhrdQw4oELdPIeeII9v6oDMzByHX9rA6RzaxHMPdY43zj-jCxv3fzvRNU22ZXGJpEpjC_1v-p_4MrImHCIJIZrDCvHOej.woff2) format('woff2');
}

.material-symbols-outlined {
    font-family: 'Material Symbols Outlined';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -moz-font-feature-settings: 'liga';
    -moz-osx-font-smoothing: grayscale;
}

/* checkbox  */
.container {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  border: 1px solid #616161;
  width: 18px;
  border-radius: 2px;
  background-color: #F5F5F5;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #008081;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* round check box  */

.container1 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.container1 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.container1:hover input ~ .checkmark1 {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.container1 input:checked ~ .checkmark1 {
  background-color: #008081;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.container1 input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.container1 .checkmark1:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}


/* code  */
.react-tel-input .dropdownStyle{
  background: #000;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0px;
  background: #fdfdfd !important;
  border: none !important;
  /* border-bottom: 1px solid white !important; */
  border-radius: 0px;
  line-height: 25px;
  height: 35px;
  width: full !important;
  /* max-width: 435px !important; */
  /* min-width: 64px !important; */
  /* width: 100% !important; */
  outline: none;
  
}


.inputStyle{
  letter-spacing: 20px;
}


.react-tel-input .flag-dropdown{
  border: 1px solid #ffffff !important;
  /* width: 0px !important; */
  margin: 10px 0px 10px 0px;
}


.react-tel-input .selected-flag {
  outline: none;
  position: relative;
  width: 38px;
  background-color: #ffffff !important;
  opacity: 2;
  border-spacing: 20px !important;
  height: 100%;
  padding: 0 0 0 8px;
  /* border: none; */
  border: 1px solid #ffffff !important;
  /* border: 1px solid #11849D !important; */
  /* border-radius: 3px 0 0 3px; */
}



/* ImageUploader.css */
.image-uploader-container {
  text-align: center;
  margin-top: 20px;
}

.file-input {
  margin-bottom: 10px;
  height: 300px; background: #ffffff;

}

.uploaded-image {
  max-width: 100%;
  max-height: 200px;
}


.fix{
  position: fixed;
}

.app {
  position: relative;
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 1; /* higher z-index to make sure it's on top */
  display: none; /* initially hidden */
}

.popup {
  background-color: white;
  padding: 20px;
  z-index: 99; /* on top of the background */
  display: none; /* initially hidden */
}

.visible {
  display: block;
}
/* Footer button hover  */
 .ss svg:hover {
  fill: #008081;
}

 .ss svg:hover path {
  fill: #ffffff;
}
/* 

::-webkit-scrollbar {
  height: 5px;
  width: 7px;
  background:#fff;
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-thumb {
  background: #E9A655;
  -webkit-border-radius: 1ex;
} */
body.popup-open {
  overflow: hidden;
  background: fixed;
  padding-right: 15px;
}
.custom-dropdown option {
  font-size: 16px;
  color: #333; 
  background-color: #fff; 
  
}

.book option{
height: 30px;
padding-top: 20px;
padding-right: 20px;
}

/* atm card button active  */
.active-button {
  background-color: #1BB66E;
}

.inactive-button {
  background-color: transparent;
}


/* mobile close  */

.mobile_close{
  position: fixed;
}



.carousel-wrapper {
  overflow: hidden;
}

.carousel {
  display: flex;
  transition: transform 0.5s ease; /* Smooth transition */
}

.carousel-slide {
  flex: 0 0 auto;
  margin-right: 10px; /* Adjust spacing between slides */
  transition: margin 0.5s ease; /* Smooth transition */
}




/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Change the width of the scrollbar */
  height: 8px; /* For horizontal scrollbar */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #008081; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Optional: Border around the scrollbar thumb */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #005f5f; /* Darker color when hovering */
}



.button1 {
  padding: 0.8em 1.8em;
  border: 2px solid #008081;
  position: relative;
  overflow: hidden;
  background-color: #008081;
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  border-radius: 15px;
  font-weight: 700;
}

.button1::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #ffffff;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
  
}

.button1:hover::before {
  width: 105%;
}

.button1:hover {
  color: #008081;
}

.button2 {
  padding: 0.8em 1.8em;
  border: 1px solid #008081;
  position: relative;
  overflow: hidden;
  background-color: #008081;
  text-align: center;
  /* text-transform: uppercase; */
  font-size: 16px;
  transition: 0.3s;
  z-index: 1;
  font-family: inherit;
  color: #ffffff;
  border-radius: 10px;
  font-weight: 700;
}

.button2::before {
  content: "";
  width: 0;
  height: 300%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background: #ffffff;
  transition: 0.5s ease;
  display: block;
  z-index: -1;
  
}

.button2:hover::before {
  width: 105%;
}

.button2:hover {
  color: #008081;
  background-color: #ffffff;
}

